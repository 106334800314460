<template>
  <div>
    <div class="parameters-wrap">
      <b-card
        no-body
        class="custom-border-top-1px"
      >
        <b-card-header>
          <h4>PHÒNG BAN</h4>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            size="sm"
            @click="openModalDepartment('create')"
          >
            <feather-icon
              icon="PlusIcon"
            />
            Phòng ban
          </b-button>
        </b-card-header>
        <b-card-body>
          <small class="text-muted">
            *Mã PB bắt đầu bằng '<strong class="text-primary">D</strong>' sẽ xuất hiện trong tìm kếm ĐNV
          </small>
          <div
            v-for="(department, idx) in departments"
            :key="department.code"
            class="py-25 hover-bg-gains"
            :class="(idx < departments.length-1) ? 'border-bottom' : ''"
          >
            <div class="d-flex">
              <b-card-text
                class="font-weight-bold mb-0 cursor-pointer d-flex flex-grow-1 align-items-center"
                @click="openModalDepartment('edit', department)"
              >
                <div class="text-primary mw-75 mr-2">
                  {{ department.name }}
                </div>
                <small>{{ department.code }}</small>
              </b-card-text>
              <div
                class="d-flex align-items-center"
                @click="openModalConfirmDeleteDepartment(department)"
              >
                <b-button
                  size="sm"
                  variant="flat-danger"
                >Xóa
                </b-button>
              </div>
            </div>
          </div>
          <span v-if="!departments.length">Chưa có phòng ban nào</span>
        </b-card-body>
      </b-card>

      <b-card
        no-body
        class="custom-border-top-1px"
      >
        <b-card-header>
          <h4>DỮ LIỆU TỰ ĐỘNG</h4>
        </b-card-header>
        <b-card-body>
          <div
            v-if="meta[META_KEY_DOCUMENT]"
            class="d-flex align-items-end"
          >
            <b-form-group
              label="Mã số phiếu hiện tại"
              label-for="metaDocument"
              class="flex-grow-1 mb-0 mr-1"
            >
              <b-form-input
                id="metaDocument"
                v-model="meta[META_KEY_DOCUMENT].meta_value"
                type="text"
                placeholder="Nhập mã số phiếu mới"
                class="text-primary"
              />
            </b-form-group>
            <b-button
              variant="primary"
              @click="handleUpdateMeta(META_KEY_DOCUMENT, meta[META_KEY_DOCUMENT].meta_value)"
            >
              Cập nhật
            </b-button>
          </div>

          <hr>

          <div
            v-if="meta[META_KEY_SMS_SETTING] && meta[META_KEY_SMS_SETTING].meta_value"
            class="d-flex align-items-end"
          >
            <b-form-group
              label="Gửi tin SMS"
              class="flex-grow-1 mb-0 mr-1 text-primary"
            >
              <template v-for="key in Object.keys(meta[META_KEY_SMS_SETTING].meta_value)">
                <b-form-checkbox
                  :id="'metaAutoSMS' + key"
                  :key="'metaAutoSMS' + key"
                  v-model="meta[META_KEY_SMS_SETTING].meta_value[key]"
                  class="mb-50"
                >
                  <span
                    class="font-weight-bolder"
                    :class="meta[META_KEY_SMS_SETTING].meta_value[key] ? 'text-primary' : 'text-danger'"
                  >
                    {{ meta[META_KEY_SMS_SETTING].meta_value[key] ? 'Tự động ' : 'Không tự động ' }}
                  </span>
                  <span class="font-weight-bolder">{{ getSmsNameByKey(key) }}</span>
                </b-form-checkbox>
              </template>
            </b-form-group>
            <b-button
              variant="primary"
              @click="handleUpdateMeta(META_KEY_SMS_SETTING, meta[META_KEY_SMS_SETTING].meta_value)"
            >
              Cập nhật
            </b-button>
          </div>
        </b-card-body>
      </b-card>
    </div>

    <b-card
      no-body
      class="custom-border-top-1px"
    >
      <b-card-header>
        <h4>MẪU VĂN BẢN</h4>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          size="sm"
          @click="openModalTemplate('create')"
        >
          <feather-icon
            icon="PlusIcon"
          />
          Mẫu
        </b-button>
      </b-card-header>
      <b-card-body>
        <draggable
          v-model="templates"
          class="list-group list-group-flush cursor-move"
          tag="ul"
          @change="updateIndexTemplates"
        >
          <transition-group
            type="transition"
            name="flip-list"
            class="template-wrapper"
          >
            <div
              v-for="(template, idx) in templates"
              :key="'template' + idx"
            >
              <div class="d-flex align-items-center">
                <b-card-text class="font-weight-bold flex-grow-1 mb-0 d-flex flex-column pr-2">
                  <div class="flex-grow-1 d-flex flex-wrap justify-content-between">
                    <span class="text-primary"><strong class="text-danger font-small-2">[{{
                      idx + 1
                    }}]</strong> {{ template.name }}</span>
                    <small
                      v-if="template.type"
                      :class="template.type==='ticket'?'text-danger':'text-success'"
                    >
                      {{ $templateTypeOptions.find(u => u.value === template.type).label }}
                    </small>
                  </div>
                  <div>
                    <small>{{ getTemplateShortName(template.file_name) }}.{{ template.file_type }}</small>
                  </div>
                </b-card-text>
                <b-dropdown
                  variant="link"
                  class="dropdown-align-right"
                  no-caret
                >
                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="align-middle text-body"
                    />
                  </template>
                  <b-dropdown-item>
                    <div @click="openModalTemplate('edit', template)">
                      <feather-icon icon="EditIcon" />
                      Chỉnh sửa
                    </div>
                  </b-dropdown-item>
                  <b-dropdown-item>
                    <div @click="downloadTemplate(template)">
                      <feather-icon icon="DownloadIcon" />
                      Tải xuống
                    </div>
                  </b-dropdown-item>
                  <b-dropdown-item>
                    <div
                      class="text-danger"
                      @click="openModalConfirmDeleteTemplate(template)"
                    >
                      <feather-icon
                        icon="TrashIcon"
                      />
                      Xóa
                    </div>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
          </transition-group>
        </draggable>
        <span v-if="!templates.length">Chưa có mẫu văn bản nào</span>
      </b-card-body>
    </b-card>

    <modal-department
      :id="modalIdCurrentDepartment"
      :ref="modalIdCurrentDepartment"
      :modal-data="currentDepartment"
      :modal-mode="modalDepartmentMode"
      @created="addDepartmentList"
      @updated="updateDepartmentList"
    />

    <b-modal
      ref="modal-confirm-delete-department"
      cancel-title="Hủy"
      ok-title="Xác nhận"
      centered
      header-text-variant="danger"
      title="Xác nhận xóa Phòng ban!"
      @ok="deleteCurrentDepartment"
    >
      <b-card-text>
        Bạn thực sự muốn <span class="text-danger">xóa</span> Phòng ban
        <span class="text-primary font-weight-bolder">
          {{ currentDepartment.code + ' - ' + currentDepartment.name }}
        </span>?
      </b-card-text>
    </b-modal>

    <modal-template
      :id="modalIdCurrentTemplate"
      :ref="modalIdCurrentTemplate"
      :modal-data="currentTemplate"
      :modal-mode="modalTemplateMode"
      @created="addTemplateList"
      @updated="updateTemplateList"
      @update="val => currentTemplate = val"
    />

    <b-modal
      ref="modal-confirm-delete-template"
      cancel-title="Hủy"
      ok-title="Xác nhận"
      centered
      header-text-variant="danger"
      title="Xác nhận xóa Mẫu văn bản!"
      @ok="deleteCurrentTemplate"
    >
      <b-card-text>
        Bạn thực sự muốn <span class="text-danger">xóa</span> Mẫu văn bản
        <span class="text-primary font-weight-bolder">{{ currentTemplate.name }}</span>?
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCardBody,
  BCardHeader,
  BCardText,
  BDropdown,
  BDropdownItem, BFormCheckbox,
  BFormGroup,
  BFormInput,
  VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import draggable from 'vuedraggable'
import {
  computed, onUnmounted, reactive, ref,
} from '@vue/composition-api'
import store from '@/store'
import demartmentStoreModule from '@/views/settings/departments/departmentStoreModule'
import ModalDepartment from '@/views/settings/parameters/ModalDepartment.vue'
import ModalTemplate from '@/views/settings/parameters/ModalTemplate.vue'
import http from '@/global/http'
import templateStoreModule from '@/views/settings/templates/templateStoreModule'
import metaStoreModule from '@/views/settings/parameters/metaStoreModule'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BFormCheckbox,
    BButton,
    BFormGroup,
    BFormInput,
    BCardHeader,
    BCardBody,
    BCardText,
    BDropdown,
    BDropdownItem,
    BCard,
    draggable,
    ModalDepartment,
    ModalTemplate,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  created() {
    this.fetchDepartments()
    this.fetchTemplates()
    this.fetchMeta(this.META_KEY_DOCUMENT)
    this.fetchMeta(this.META_KEY_SMS_SETTING)
  },
  methods: {
    getSmsNameByKey(key) {
      if (key === 'is_auto_send_sms_create') return 'Gửi SMS khi tạo HS'
      if (key === 'is_auto_send_sms_contract_sign') return 'Gửi SMS khi ký hợp đồng'
      if (key === 'is_auto_send_sms_verify_deny') return 'Gửi SMS khi trả hồ sơ'
      if (key === 'is_auto_send_sms_liquid_completed') return 'Gửi SMS khi thanh lý'
      if (key === 'is_auto_send_sms_update_debt') return 'Gửi SMS khi tạo nhập thu nợ'
      return `Gửi SMS #${key}`
    },
    handleUpdateMeta(keyName, value) {
      const meta = this.meta[keyName]
      if (keyName === this.META_KEY_DOCUMENT && !meta.meta_value) {
        this.$toastr('Nhập số phiếu yêu cầu', 'danger')
        return
      }
      const data = ({
        name: keyName,
        meta_value: value,
      })
      this.$action(this.updateMeta(data), true)
    },
    updateIndexTemplates({
      oldIndex,
      newIndex,
    }) {
      const temp = this.templates[oldIndex]
      this.templates[oldIndex] = this.templates[newIndex]
      this.templates[newIndex] = temp
      this.updatePriorityTemplates({
        ids: this.templates.map(t => t.id),
      })
    },
    fetchDepartments() {
      this.$action(store.dispatch('department/fetchDepartments'))
        .then(res => {
          this.departments = res.data
        })
    },
    openModalDepartment(mode, data = {}) {
      this.modalDepartmentMode = mode
      this.currentDepartment = data
      this.$refs[this.modalIdCurrentDepartment].$refs['btn-open-modal-department'].click()
    },
    openModalConfirmDeleteDepartment(d) {
      this.currentDepartment = d
      this.$refs['modal-confirm-delete-department'].show()
    },
    downloadTemplate(data = {}) {
      this.openLinkInNewTab(data.path)
    },
    openModalTemplate(mode, data = {}) {
      this.modalTemplateMode = mode
      this.currentTemplate = data
      this.$refs[this.modalIdCurrentTemplate].$refs['btn-open-modal-template'].click()
    },
    openModalConfirmDeleteTemplate(d) {
      this.currentTemplate = d
      this.$refs['modal-confirm-delete-template'].show()
    },
  },
  setup() {
    const META_KEY_DOCUMENT = 'document_code'
    const META_KEY_SMS_SETTING = 'sms_setting'

    const DEPARTMENT_STORE_MODULE_NAME = 'department'
    const TEMPLATE_STORE_MODULE_NAME = 'template'
    const META_STORE_MODULE_NAME = 'meta'
    if (!store.hasModule(DEPARTMENT_STORE_MODULE_NAME)) store.registerModule(DEPARTMENT_STORE_MODULE_NAME, demartmentStoreModule)
    if (!store.hasModule(TEMPLATE_STORE_MODULE_NAME)) store.registerModule(TEMPLATE_STORE_MODULE_NAME, templateStoreModule)
    if (!store.hasModule(META_STORE_MODULE_NAME)) store.registerModule(META_STORE_MODULE_NAME, metaStoreModule)
    onUnmounted(() => {
      if (store.hasModule(DEPARTMENT_STORE_MODULE_NAME)) store.unregisterModule(DEPARTMENT_STORE_MODULE_NAME)
      if (store.hasModule(TEMPLATE_STORE_MODULE_NAME)) store.unregisterModule(TEMPLATE_STORE_MODULE_NAME)
      if (store.hasModule(META_STORE_MODULE_NAME)) store.unregisterModule(META_STORE_MODULE_NAME)
    })

    const toast = useToast()

    const departments = ref([])
    const currentDepartment = ref({})
    const modalDepartmentMode = ref('create')
    const modalIdCurrentDepartment = computed(() => `modal-department-${currentDepartment && currentDepartment.code}`)

    const meta = reactive({
      [META_KEY_DOCUMENT]: {
        meta_key: META_KEY_DOCUMENT,
        meta_value: '',
      },
      [META_KEY_SMS_SETTING]: reactive({
        meta_key: META_KEY_SMS_SETTING,
        meta_value: {
          is_auto_send_sms_create: false, // Tự động gửi SMS khi tạo HS
          is_auto_send_sms_contract_sign: false, // Tự động gửi SMS khi ký hợp đồng
          is_auto_send_sms_verify_deny: false, // Tự động gửi SMS khi trả hồ sơ
          is_auto_send_sms_liquid_completed: false, // Tự động gửi SMS khi thanh lý
          is_auto_send_sms_update_debt: false, // Gửi SMS khi tạo nhập thu nợ
        },
      }),
    })
    const templates = ref([])
    const currentTemplate = ref({})
    const modalTemplateMode = ref('create')
    const modalIdCurrentTemplate = computed(() => `modal-template-${currentTemplate && currentTemplate.id}`)

    const modalErrorText = ref('')

    const addDepartmentList = d => {
      departments.value.push(d)
    }
    const updateDepartmentList = d => {
      departments.value = departments.value.map(i => (i.id === d.id ? d : i))
    }
    const deleteDepartment = ({ id }) => {
      http.api(store.dispatch('department/deleteDepartment', { id }), () => {
        departments.value = departments.value.filter(i => i.id !== id)
      })
    }
    const deleteCurrentDepartment = () => {
      deleteDepartment({ id: currentDepartment.value.id })
    }

    const fetchMeta = keyName => http.api(store.dispatch('meta/fetchMeta', { name: keyName }), res => {
      meta[keyName] = res.data || {}
    })
    const updateMeta = data => http.api(store.dispatch('meta/updateMeta', data), res => {
      meta[res.data.meta_key] = res.data || {}
    })
    const fetchTemplates = () => http.api(store.dispatch('template/fetchTemplates'), res => {
      templates.value = res.data || []
    })
    const fetchTemplate = () => http.api(store.dispatch('template/fetchTemplate'), res => {
      currentTemplate.value = res.data || {}
    })
    const deleteTemplate = ({ id }) => http.api(store.dispatch('template/deleteTemplate', { id }), () => {
      templates.value = templates.value.filter(i => i.id !== id)
    })
      .then(() => toast({
        component: ToastificationContent,
        props: {
          title: 'Thành công',
          variant: 'success',
        },
      }))
      .catch(err => toast({
        component: ToastificationContent,
        props: {
          title: err.message,
          variant: 'danger',
        },
      }))

    const addTemplateList = d => {
      templates.value.push(d)
    }
    const updateTemplateList = d => {
      templates.value = templates.value.map(i => (i.id === d.id ? d : i))
    }
    const deleteCurrentTemplate = () => {
      deleteTemplate({ id: currentTemplate.value.id })
    }
    const updatePriorityTemplates = ids => http.api(store.dispatch('template/updatePriorityTemplates', ids || templates.value.map(t => t.id)))
    const updateTemplate = data => http.api(store.dispatch('template/updateTemplate', data), res => {
      currentTemplate.value = res.data || {}
    })

    const renderTemplate = data => http.api(store.dispatch('template/renderTemplate', data), res => {
      console.log(res)
    })

    return {
      updateTemplate,
      fetchTemplate,
      updateMeta,
      fetchMeta,
      fetchTemplates,
      renderTemplate,
      updatePriorityTemplates,
      deleteTemplate,
      addDepartmentList,
      updateDepartmentList,
      modalIdCurrentDepartment,
      deleteCurrentDepartment,
      deleteDepartment,
      departments,
      currentDepartment,
      modalDepartmentMode,
      currentTemplate,
      templates,
      modalErrorText,
      deleteCurrentTemplate,
      addTemplateList,
      updateTemplateList,
      modalIdCurrentTemplate,
      modalTemplateMode,
      meta,
      META_KEY_DOCUMENT,
      META_KEY_SMS_SETTING,
    }
  },
}
</script>

<style lang="scss">
.parameters-wrap {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: .5rem;
}

.template-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: .5rem 1rem;

  > * {
    box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
    padding: 1rem;
    border-left: 2px solid gainsboro;
    border-radius: 4px;
  }
}

@media (max-width: 768px) {
  .parameters-wrap {
    grid-template-columns: 1fr;
  }
  .template-wrapper {
    grid-template-columns: 1fr;
  }
}
</style>
